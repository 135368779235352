import axios from 'axios';
import { useEffect, useState } from 'react'
import useAxios, { configure } from 'axios-hooks'
import {useGlobal} from 'reactn';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';


const cache = false

const instance = axios.create({
  baseURL: 'https://notarzt-heinsberg.de/api/',
  withCredentials: true,
})

configure({ instance, cache, manual: false, useCache: false, ssr: false, autoCancel: true })

export function AuthProvider({children}) {
    const [loggedin,setLoggedin] = useState(true)
    const [axios,setAxios] = useGlobal('axios')
    


    useEffect(() => {
        console.log('add interceptor')
        const interceptor = instance.interceptors.response.use(
            function (response) {
                console.log('response',response)
                if (!loggedin) {setLoggedin(true)}
                return response;
            }, function (error) {
                console.log('error',error)
                if (error.response.status === 401) {
                    setLoggedin(false)
                    return error
                }
                return error
            }
            );
        setAxios(instance)
    },[])

    useEffect(() => {
        console.log('logged in:',loggedin)
    },[loggedin])



    return (
        <>
        <LoginDialog loggedin={loggedin}/>
        {loggedin && children}
        </>
    )
}




function LoginDialog({loggedin}) {
    const [isAdmin,setIsAdmin] = useGlobal('admin')
    const [{data,loading, error}, executelogin] = useAxios({url: 'login' ,method: 'POST'},{ manual: true })
    const [username,setUsername] = useState('christoph.dohmen@rd-heinsberg.de');
    const [password,setPassword] = useState('login');


    useEffect(() => {
      if (error && error.response?.status === 401) 
        { setIsAdmin(false)
          console.log('login failed')
        }
    },[error])

        

    useEffect(() => {    
      if (data && data.status === 200) {
        if (data.isAdmin) {setIsAdmin(true)}
      }
    },[data])
  
    const handleEnter = (e) => {
        if (e.key === 'Enter') {handleLogin()}
    }

    const handleLogin = () => {
        executelogin({data:{'username':username,'password':password}}).then(e => console.log(loggedin))
    }

    return (
        <Dialog open={!loggedin}>
            <DialogTitle>Login</DialogTitle>
            <DialogContent style={{padding:'5px'}}>
        <TextField label="Benutzername"  value={username} onChange={e => setUsername(e.target.value)}/><br/>
        <TextField label="Passwort" value={password} type='password' style={{marginTop:'5px'}}
                    onChange={e => setPassword(e.target.value)} onKeyPress={e => handleEnter(e)}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" primary="true" onClick={(event) => handleLogin()}>Anmelden</Button>
            </DialogActions>
        </Dialog>
    )
}