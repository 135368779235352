import { AddCircle, Block, Clear, Download, Edit, EditAttributesRounded, HandymanOutlined, RadioButtonUnchecked, Rotate90DegreesCw, Search, TaskAlt } from "@mui/icons-material";
import { Tabs, Tab, Box, ListItem, ListItemButton, List, ListItemText, Dialog, DialogTitle, DialogContent, Paper, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Divider, DialogActions, Button, ToggleButtonGroup, ToggleButton, InputAdornment, Snackbar, Autocomplete, ListItemSecondaryAction } from "@mui/material";
import { Container } from "@mui/system";
import { useState, useEffect } from "react";
import {useGlobal} from 'reactn';
import { MessageCenter } from "./messagecenter";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '@sweetalert2/theme-material-ui/material-ui.css';
import { Bereichsauswahl } from "./utils";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import './manage.css'
import { useParams } from "react-router";
import { UserAccordionReports } from "./reports";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";





export function Bescheinigungen({userid}) {
    const [axios] = useGlobal('axios')
    const [toggletasks,setToggletasks] = useGlobal('tasks')
    const [userliste,setUserliste] = useState([])
    const [viewuser,setViewuser] = useState(null)
    const [status,setStatus] = useState(false)
    const [cmeliste,setCmeliste] = useState([])
    const [cmebyuser,setCmebyuser] = useState({})
    const [filter,setFilter] = useState('')
    const [revisit,setRevisit] = useState(false)
    const [showcme,setShowcme] = useState(false)
    const [snackbarmessage,setSnackbarmessage] = useState(false)
    const [tabvalue,setTabvalue] = useState(0)
    const { urlparameter } = useParams()
    const statuslist = ['pending','granted','rejected']

    useEffect(() => {
       getcmelist()
    },[])



    useEffect(() => {
        console.log(urlparameter)
        if (userid) {
            axios.get('userdata',{ params: { user: userid} }).then(u => setViewuser(u.data))
        } else {
            setViewuser(null)
        }
    },[userid])

    useEffect(() => {
        console.log(viewuser)
        if (viewuser) {
            //axios.get('bescheinigungen',{ params: { user: viewuser._id } }).then(data => setCmeliste(data.data))
            axios.get('checkbeschstatus',{ params: { withreports: true, userid:viewuser._id }}).then(data => setStatus(data.data))
        } else {
            axios.get('allbescheinigungen').then(data => {setCmeliste(data.data)})
            setStatus(false)
        }
    },[viewuser])

     const getcmelist = () => {
        axios.get('allbescheinigungen').then(data => {
            setCmeliste(data.data)
        })
        axios.get('get_userliste').then(data => {
            console.log('userliste',data)
            setUserliste(data.data)
        })
        axios.get('getcmebyuser').then(data => {
            setCmebyuser(data.data)
        })
    }

    const closeCMEEditDialog = () => {
        setRevisit(false)
        getcmelist()
    }

    // const downloadBescheinigung = (e,cme) => {
    //     console.log('dl',e)
    //     let link = document.createElement('a')
    //     link.href = 'https://notarzt-heinsberg.de/api/bescheinigung/'+cme;
    //     link.target = '_blank';
    //     document.body.appendChild(link);
    //     link.click();
    // }


    const handleCMEDialogclose = (result) => {
        if (result.reload) {
            getcmelist()
        }
        if (result.message) {
            setSnackbarmessage(result.message)
        }
        setShowcme(false)
    }


    const download_excel_list = () => {
        let fitlerstring = filter || '__'
        let link = document.createElement('a')
        link.href = 'https://notarzt-heinsberg.de/api/getcmelist/'+fitlerstring;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
    }


    return (
        <Container maxWidth='sm' className='ulistcontainer'>
        <Tabs centered value={tabvalue} onChange={(e,v) => setTabvalue(v)}>
            <Tab label='nach Bescheinigung'/>
            <Tab label='nach Notarzt'/>
        </Tabs>

            {/* <TextField label='Filter' type='search' fullWidth value={filter} onChange={e => setFilter(e.target.value.toLowerCase())} size="small" InputProps={{endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,}}/> */}
        {tabvalue == 0 && <>
            <Autocomplete options={userliste} getOptionLabel={(o) => o.name?.vorname+' '+o.name?.nachname} filterSelectedOptions
                    onChange={(event, newValue) => {setViewuser(newValue);}} autoSelect autoHighlight
                    value={viewuser}
                    renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Notarzt"
                        />
                      )}
                
                />
            {!viewuser && 
            <List>
            {cmeliste.sort((a,b) => statuslist.indexOf(a.status) - statuslist.indexOf(b.status)).map((cme,index) => 
                <ListItem className={'cme '+cme.status} key={'cmeliste_'+cme._id} onClick={e => setShowcme(cme)}>
                    <ListItemText primary={cme.username}
                    secondary = {<>
                        <div>Datum: {cme.datum}</div>
                        <div>Punkte: {cme.punkte}</div>
                    </>}
                    />
                </ListItem>                        
            )}
            </List>
            }
            {viewuser && 
            <UserAccordionReports status={status} docmestuff={e =>  setShowcme(e)}/>
            }
        </>
        }
        {tabvalue == 1 && <>
            <TextField label='Suche' onChange={e => setFilter(e.target.value)} fullWidth value={filter}
            InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                    <Clear onClick={e => setFilter('')}/>
                </InputAdornment>,
            }}
            /><Button variant="contained" onClick={e => download_excel_list()}>Als Excel-Datei speichern</Button>
            <List component="nav">
            {cmebyuser.filter(v => (filter.length > 2) ? v.user.toLowerCase().includes(filter.toLowerCase()) || v.groups.toLowerCase().includes(filter.toLowerCase()) : v).map((na,i) => 
            <>
            <ListItem key={'prilist'+i} className='cme'>
                <ListItemText primary={na.user} secondary={na.groups}/>
                <List component="div" disablePadding>
                    {na.cme.map((zr,j) => 
                    <ListItem key={'sl'+j}>
                        <ListItemText primary={zr.zeitraum} />
                        <ListItemSecondaryAction>{zr.punkte}</ListItemSecondaryAction>
                    </ListItem>
                    )}
                </List>
            </ListItem>               
            </>
            )}
            </List>



        </>
        }
        <ModCMEDialog cme={showcme} close={m => handleCMEDialogclose(m)}/>
        <Snackbar open={!!snackbarmessage} message={snackbarmessage} autoHideDuration={5000} onClose={e => setSnackbarmessage(false)}/>
        </Container>
    )
}


function ModCMEDialog({cme,close}) {
    const [axios] = useGlobal('axios')
    const [modcme,setModcme] = useState({})
    const [duplicate,setDuplicate] = useState(false)
    const [rot,setRot] = useState(0)

    useEffect(() => {
            console.log('modcmeDialog',cme)
            setModcme(cme)
            setRot(0)
            setDuplicate(false)
    },[cme])


    const delcme = () => {
        axios.post('deleteBescheinigung',{...modcme,id:modcme._id}).then(res => 
            close({'reload':true,'message':'Bescheinigung gelöscht'})
        )
    }

    const savecme = () => {
        axios.post('statuschange',{...modcme,duplicate:duplicate,status:'granted'}).then(res =>
        close({'reload':true,'message':'Bescheinigung freigegeben'})
        )
    }

    const updatecme = () => {
        axios.post('updatecme',{...modcme,duplicate:duplicate}).then(res =>             
            close({'reload':true,'message':'Bescheinigung geändert'})
            )
    }

    const resetcme = () => {
        axios.post('statuschange',{...modcme,status:'pending'}).then(res =>             
            close({'reload':true,'message':'Bescheinigung zurückgesetzt'})
            )        
    }

   


    return(
        <Dialog open={!!modcme} fullWidth maxWidth='md'>
            <DialogTitle>Bescheinigung bearbeiten</DialogTitle>

            <DialogContent>
            <div style={{height:'60vh'}}>   
            {/* Hier split nach Mimetype              */}
            {modcme && modcme?._id && 
            <>
                {modcme.mimetype === 'application/pdf' ?
                    <iframe src={'https://notarzt-heinsberg.de/api/bescheinigung/'+cme._id+'#view=fitH'} title="testPdf" height="100%" width="100%" />
                    :
                    <TransformWrapper centerOnInit style={{height:'inherit'}} wrapperClass='transformwrapper'>
                        <TransformComponent>
                                <img src={'https://notarzt-heinsberg.de/api/bescheinigung/'+modcme._id} alt="test" />
                        </TransformComponent>
                    </TransformWrapper>                 
                }
            </>            
            }
            </div>
            <div style={{marginTop:'8px'}}>Letzter Bescheinigungszeitraumwechsel von {modcme?.username} am <b>{modcme?.lastblockend}</b></div>
            <div style={{marginTop:'8px'}}><i>Zum Aufteilen der Bescheinigung (+) klicken und Datum vor/nach des Zeitraumwechsels mit entsprechender Punktzahl wählen.</i></div>
            <div className="cmedialogding">
                <TextField  size='small' style={{flexGrow:'1'}} label='Datum' type='date' InputLabelProps={{ shrink: true }} value={modcme?.datum} onChange={e => setModcme({...modcme,datum:e.target.value})}/>
                <TextField size='small' style={{flexGrow:'1'}} label='Punkte' type='number' InputLabelProps={{ shrink: true }} value={modcme?.punkte} onChange={e => setModcme({...modcme,punkte:e.target.value})}/>
                {!duplicate && <AddCircle onClick={e => setDuplicate({'datum':modcme?.datum,'punkte':0})}/>}
            </div>
            {duplicate && 
                    <div className="cmedialogding">
                    <TextField  size='small' style={{flexGrow:'1'}} label='Datum' type='date' InputLabelProps={{ shrink: true }} value={duplicate?.datum} onChange={e => setDuplicate({...duplicate,datum:e.target.value})}/>
                    <TextField size='small' style={{flexGrow:'1'}} label='Punkte' type='number' InputLabelProps={{ shrink: true }} value={duplicate?.punkte} onChange={e => setDuplicate({...duplicate,punkte:e.target.value})}/>
                </div>                        
            }
            </DialogContent>

            <DialogActions>
                <Button variant='outlined' onClick={e => close({reload:false,message:false})}>abbrechen</Button>
                <Button variant='outlined' onClick={e => delcme()}>löschen</Button>
                <Button variant='contained' onClick={e => updatecme()}>speichern</Button>
                <Button variant='contained' onClick={e => {modcme.status === 'granted' ?  resetcme() : savecme()}}>
                    {modcme.status === 'pending' ? 'akzeptieren' : 'auf "In Bearbeitung" zurücksetzen'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}



