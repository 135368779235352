import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material"
import { useEffect } from "react"


export const bereiche = ['ERK','GEI','HSB','WGB','LNA']

export function Bereichsauswahl({styleoverwrite,selected,change,disabled,notall}) {

    {/* 
            <Bereichsauswahl  selected={entry?.groups || []} change={g => setEntry({...entry,groups:g})}/> 
            <Bereichsauswahl  selected={groups} change={g => setGroups(g)}/>
    */}
    
        const modlist = (item,checked) => {
            if (checked){
                selected.push(item)
                change(selected)
                }
            else 
                change(selected.filter(i => i !== item))
        }

    
        return (
            <div style={styleoverwrite}>
                
                <FormGroup row className='buttongroup'>
                {!notall && <FormControlLabel 
                    control={ <Switch checked={bereiche.every(b => selected.includes(b))} onChange={e => bereiche.forEach(b => modlist(b,true))} /> }
                    label='ALLE'
                />}
                    {bereiche && bereiche.map((b,i) => 
                    <FormControlLabel key={'b_'+i} 
                        control={
                            <Switch checked={selected.includes(b)} disabled={disabled}
                            onChange={e => modlist(b,e.target.checked)} />
                        } 
                        label={b}
                    />
                    )}
                    {disabled && <Typography variant='caption'>Bereiche können nur von der ärztlichen Leitung geändert werden</Typography>}
                </FormGroup>
            </div>
        )
    }