import { Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography, Box, Stepper, StepLabel, Step, Snackbar } from "@mui/material";
import { configure } from 'axios-hooks'
import Axios from 'axios'
import {useGlobal} from 'reactn';
import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { useLocation, useNavigate,  } from "react-router";
import { Bereichsauswahl } from "./utils";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { addYears, format } from 'date-fns'
import '@sweetalert2/theme-material-ui/material-ui.css';
import './auth.css'
import { isMobile } from "react-device-detect";


const swal = withReactContent(Swal)

const cache = false
const instance = Axios.create({
  baseURL: 'https://notarzt-heinsberg.de/api/',
  withCredentials: true,
})

//configure({ axios, cache, manual: false, useCache: false, ssr: false, autoCancel: true })

// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   }, function (error) {
//        return error
//   }
// );

export function Auth({children}) {
    const [loggedin,setLoggedin] = useState(true)
    const [isAdmin,setIsAdmin] = useGlobal('admin')
    const [showas,setShowas] = useGlobal('showas')
    const [toggletasks,setToggleTasks] = useGlobal('tasks')
    const [axios,setAxios] = useGlobal('axios')
    const [lastrequestinterceptor,setLastrequestinterceptor] = useState(false)
    const [lastrequest,setLastrequest] = useState()
    const [asknot,setAsknot] = useState(false)
    const currentlocation = useLocation()
    const navigate = useNavigate()


   


    useEffect(() => {
      const intercept = instance.interceptors.response.use(
        (response) => {
          //  console.log('response',response,loggedin)
          if (response?.status == 200) {
            setLoggedin(true)
            if (response.headers['user-is-admin']) {
              setIsAdmin(true)
              setToggleTasks(true)
            }
            if (response.config.url === 'login' || response.config.url === 'refresh') {
              // console.log('navigate to',currentlocation.pathname)
              navigate(0)
            }            
          }
          return response;
        },
        (error) => {
            //  console.log('error',error.response.status,loggedin)
             if (error.response.status == 401)
              setLoggedin(false)
              setLastrequest(error.config.url)
             return Promise.reject(error);
        }
      );
     
      setAxios(instance)
      setIsAdmin(false)
      // const refresher = setInterval(() => {instance.post('refresh',{})},600000)
      
    },[])


    useEffect(() => {
      if (isAdmin) {
        let inst = axios
        console.log('update request interceptor',showas?._id)
        if (showas){
        const intercept_request =  inst.interceptors.request.use(
          (config) => {
            config.params = {
              ...config.params,
              showas: showas?._id
            }
          return config;
        })
        setLastrequestinterceptor(intercept_request)
        } else {
          if (lastrequestinterceptor){
          inst.interceptors.request.eject(lastrequestinterceptor)
          setLastrequestinterceptor(false)
          }
        }
        setAxios(inst)
      }
    },[showas])




    
   
    return (
      <>
        <LoginDialog open={!loggedin}/>
        {children}
        {/* <Button onClick={logout} className='topright'>Logout</Button> */}


        </>
    )
}


function LoginDialog({open}) {
    const [axios,setAxios] = useGlobal('axios')
    const [isAdmin,setIsAdmin] = useGlobal('admin')
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [mistry,setMistry] = useState(0)
    const [remindme,setRemindme] = useState(false)
    const [register,setRegister] = useState(false)
    const [resetpw,setResetpw] = useState(false)
    const currentlocation = useLocation()


    useEffect(() => {
      if (axios && open){
          axios.post('refresh',{}).then(data => console.log('checked refresh-token',data))
          if (currentlocation.pathname == '/register') {
            setRegister(true)
          }
        }
    },[axios,open])


    useEffect(() => {console.log('remindme',remindme)},[remindme])

         
    const handleEnter = (e) => {
        if (e.key === 'Enter') {handleLogin()}
    }

    const handleLogin = () => {
      setMistry(mistry+1)
      axios.post('login',{'username':username,'password':password,'remindme':remindme}).then(resp => console.log('login',resp))
    }

    return (
      <>
        <Dialog open={open} PaperProps={{style:{padding:'20px'},className:'userinfodialog'}}>
            <DialogTitle>Login</DialogTitle>
            <DialogContent style={{padding:'25px'}}>
        <TextField label="Benutzername"  value={username} onChange={e => setUsername(e.target.value)}/><br/>
        <TextField label="Passwort" value={password} type='password' style={{marginTop:'5px'}}
                    onChange={e => setPassword(e.target.value)} onKeyPress={e => handleEnter(e)}/>
                    <br/>
          {mistry > 2 && <div style={{width:'100%'}}>Passwort vergessen? <Link onClick={e => setResetpw(true)}>hier</Link> klicken</div>}
          <FormControlLabel  label='angemeldet bleiben' control={<Checkbox checked={!remindme} onClick={e=> setRemindme(!remindme)}/>} style={{marginTop:'5px',paddingLeft:'20px'}} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" primary="true" onClick={(event) => handleLogin()}>Anmelden</Button>
            </DialogActions>
            <div style={{width:'100%'}}>Noch nicht registriert? <Link onClick={e => setRegister(true)}>hier</Link> klicken</div>
        </Dialog>
      <ResetPWDialog open={resetpw} close={e => setResetpw(false)}/>
      <RegisterDialog open={register} close={e => setRegister(false)}/>
      </>
    )
}


function ResetPWDialog({open,close}) {
  const [axios,setAxios] = useGlobal('axios')
  const [username,setUsername] = useState('')
  const [sent,setSent] = useState(false)

  const handlePWReset = () => {
    axios.post('pwresetantrag',{username:username}).then(resp => setSent(true))
  }



  return (
    <Dialog open={open} PaperProps={{style:{padding:'20px'},className:'userinfodialog'}}>
      <DialogTitle>Passwort zurücksetzen</DialogTitle>
      {!sent ? <>
      <DialogContent>
      <TextField label="Email" helperText='Bitte geben Sie die zur Registierung verwendete Email-Adresse an' value={username} onChange={e => setUsername(e.target.value)} fullWidth/>
      </DialogContent>
      <DialogActions>
            <Button variant="outlined" color="primary" primary="true" onClick={(event) => close()}>abbrechen</Button>
            <Button variant="contained" color="primary" primary="true" disabled={!username} onClick={(event) => handlePWReset()}>absenden</Button>
      </DialogActions>
      </>
      :
      <>
      <DialogContent>
        Wenn die angegebene Emailadresse im System ist, dann wurde ein neues Passwort zugestellt. Nach Einloggen können Sie das Passwort über die Benutzereinstellungen ändern.
        Bitte laden Sie die Seite neu.
      </DialogContent>
      </>
      }
    </Dialog>
  )


}


function RegisterDialog({open,close}) {
  const [axios,setAxios] = useGlobal('axios')
  const [password,setPassword] = useState('');
  const [pw2,setPw2] = useState('')
  const [groups,setGroups] = useState([])
  const [name,setName] = useState({vorname:'',nachname:''})
  const [anrede,setAnrede] = useState('')
  const [email,setEmail] = useState('')
  const [mobile,setMobile] = useState('')
  const [nastart,setNastart] = useState(new Date(2022,11,31))
  const [nastartset,setNastartset] = useState(false)
  const [oldna,setOldna] = useState(false)
  const [notvalid,setNotvalid] = useState(true)
  const [activestep,setActivestep] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    // fullcheck true > registrierung möglich
    let fullcheck = name.vorname.length && name.nachname.length && 
                    email.length && groups.length && password.length > 3 && 
                    password == pw2 && email.includes('@')
    setNotvalid(!fullcheck)
  },[password,email,name,groups,pw2])

  useEffect(() => {console.log(nastart)},[nastart])

  const checkExisting = () => {
    axios.post('check_existing',{email:email}).then(resp => 
      {if (resp.data.exists) {
        swal.fire({
          text : 'Email-Adresse schon belegt',
         confirmButtonText: 'ok',
          }).then(r => setEmail(''))
      }})
  }

 
  const handleRegister = () => {
      axios.post('register',{password:password,email:email,mobile:mobile,groups:groups,name:name,anrede:anrede,nastart:nastart}).then(resp =>{
         console.log('register',resp)
         swal.fire({title: 'Vielen Dank',
          text : 'Sie werden über die manuelle Freischaltung per Email informiert',
         confirmButtonText: 'ok',
          }).then(next => {
            navigate('/blog')
          })
         close()
        })
  }

  const oldreturn = (
      <Dialog open={open} PaperProps={{style:{padding:'20px'},className:'userinfodialog'}}>
          <DialogTitle>Registrierung</DialogTitle>
          <DialogContent style={{padding:'5px'}}>
      <Paper className="uinner">
        <TextField label="Email-Adresse" value={email} helperText='Adresse ist gleichzeitig Login-Name' error={!email || !email.includes('@')} fullWidth style={{marginTop:'8px'}} onChange={e => setEmail(e.target.value)} onBlur={e => checkExisting()}/><br/>
        <TextField label="Passwort" value={password} error={password.length < 5} type='password' style={{marginTop:'8px',width:'50%'}} onChange={e => setPassword(e.target.value)}/>
        <TextField label="Passwort wiederholen" error={pw2 !== password} value={pw2} type='password' style={{marginTop:'8px',width:'50%'}} onChange={e => setPw2(e.target.value)}/>
      </Paper>
      <Paper className="uinner">
        <FormControl style={{width:'20%',marginTop:'8px'}}>
        <InputLabel>Anrede</InputLabel>
        <Select value={anrede} label='Anrede'  InputLabelProps={{ shrink: true }}  onChange={e => setAnrede(e.target.value)}>
          <MenuItem value={'Herr'}>Herr</MenuItem>
          <MenuItem value={'Frau'}>Frau</MenuItem>
        </Select>
        </FormControl>
        <TextField label='Vorname' style={{marginTop:'8px',width:'40%'}} error={!name.vorname} value={name.vorname} onChange={e => setName({...name,vorname:e.target.value})}/>
        <TextField label='Nachname' style={{marginTop:'8px',width:'40%'}} error={!name.nachname} value={name.nachname} onChange={e => setName({...name,nachname:e.target.value})}/>
        <TextField label='Handynummer'  style={{marginTop:'8px'}} fullWidth helperText='für dringende SMS' value={mobile} onChange={e => setMobile(e.target.value)}/>
      </Paper>
      <Paper className="uinner">
        <Typography variant='h6'>In welchen NEF-Bereichen sind Sie tätig?</Typography>
        <Bereichsauswahl  selected={groups || []} notall={true} change={g => setGroups([...g])}/>
        <Divider/>
        <Box>
        <Typography variant='h6'>Datum der ersten notärztlichen Tätigkeit</Typography>
          <div className='nastartmain'>
            <div className='nastartpart'>
              <FormControlLabel control={<Checkbox checked={oldna}/>} onClick={e => {setOldna(true);setNastart(new Date(2022,11,31))}} label='vor dem 01.04.2018'/>
            </div>
            <div className='nastartpart'> oder</div>
            <div className='nastartpart'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker    label="" 
                inputFormat="dd.MM.yyyy"
                value={oldna ? '' : nastart}
                onChange={n => {
                  setOldna(false);
                  setNastart(n)
                }}
                renderInput={(params) => <TextField fullWidth style={{marginTop:'8px',marginBottom:'8px'}}  {...params} error={params.error && !oldna} helperText='Datum wählen'/>}
                />
              </LocalizationProvider>
            </div>
          </div>

        </Box>

      </Paper>
          </DialogContent>
          <DialogActions>
          <Button variant="outlined" color="primary" primary="true" onClick={(event) => close()}>abbrechen</Button>
              <Button variant="contained" color="primary" primary="true" disabled={notvalid} onClick={(event) => handleRegister()}>Registieren</Button>
          </DialogActions>
      </Dialog>
  )


  return (
    <Dialog open={open} PaperProps={{style:{padding:'20px'},className:'userinfodialog'}}>
    <DialogTitle>Registrierung</DialogTitle>
    <DialogContent style={{padding:'5px'}}>
      <Stepper activeStep={activestep}>
        <Step key={'step0'} completed={activestep > 0} >
          <StepLabel>Login</StepLabel>
        </Step>
        <Step key={'step1'} completed={activestep > 1} >
          <StepLabel>Kontakt</StepLabel>
        </Step>
        <Step key={'step2'} completed={activestep > 2} >
          <StepLabel>sonst.</StepLabel>
        </Step>
      </Stepper>
    {(activestep === 0) &&
      <Paper className="uinner">
        <TextField label="Email-Adresse" value={email} helperText='Adresse ist gleichzeitig Login-Name' error={!email || !email.includes('@')} fullWidth style={{marginTop:'8px'}} onChange={e => setEmail(e.target.value)} onBlur={e => checkExisting()}/><br/>
        <TextField label="Passwort" value={password} helperText='min 5 Zeichen' error={password.length < 5} type='password' style={{marginTop:'8px',width: isMobile ? '100%' : '50%'}} onChange={e => setPassword(e.target.value)}/>
        {isMobile && <br/>}
        <TextField label="Passwort wiederholen" error={pw2 !== password} value={pw2} type='password' style={{marginTop:'8px',width: isMobile ? '100%' : '50%'}} onChange={e => setPw2(e.target.value)}/>
      <Box className='stepperbox'>
        <Button disabled={true} variant='outlined'>zurück</Button>
        <Button variant='contained' disabled={(!email || !password || password.length < 5 || password !== pw2)} onClick={e => setActivestep(1)}>weiter</Button>
      </Box>
      </Paper>
    }
    {(activestep === 1) &&
<Paper className="uinner">
  <FormControl style={{width:isMobile ? '60%' : '20%',marginTop:'8px'}}>
  <InputLabel>Anrede</InputLabel>
  <Select value={anrede} label='Anrede'  InputLabelProps={{ shrink: true }}  onChange={e => setAnrede(e.target.value)}>
    <MenuItem value={'Herr'}>Herr</MenuItem>
    <MenuItem value={'Frau'}>Frau</MenuItem>
  </Select>
  </FormControl>
  {isMobile && <br/>}
  <TextField label='Vorname' style={{marginTop:'8px',width: isMobile ? '100%' :'40%'}} error={!name.vorname} value={name.vorname} onChange={e => setName({...name,vorname:e.target.value})}/>
  {isMobile && <br/>}
  <TextField label='Nachname' style={{marginTop:'8px',width:isMobile ? '100%' :'40%'}} error={!name.nachname} value={name.nachname} onChange={e => setName({...name,nachname:e.target.value})}/>

  <TextField label='Handynummer'  style={{marginTop:'8px'}} fullWidth helperText='für dringende SMS' value={mobile} onChange={e => setMobile(e.target.value)}/>
  <Box className='stepperbox'>
        <Button variant='outlined' onClick={e => setActivestep(0)}>zurück</Button>
        <Button variant='contained' disabled={(!name.vorname || !name.nachname)} onClick={e => setActivestep(2)}>weiter</Button>
    </Box>
</Paper>
}
{(activestep === 2) &&
<Paper className="uinner">
  <Typography variant='h6'>In welchen NEF-Bereichen sind Sie tätig?</Typography>
  <Bereichsauswahl  selected={groups || []} notall={true} change={g => setGroups([...g])}/>
  <Divider/>
  <Box>
  <Typography variant='h6'>Datum der ersten notärztlichen Tätigkeit</Typography>
    <div className='nastartmain'>
      <div className='nastartpart'>
        <FormControlLabel control={<Checkbox checked={oldna}/>} onClick={e => {setOldna(true);setNastartset(true);setNastart(new Date(2022,11,31))}} label='vor dem 01.04.2018'/>
      </div>
      <div className='nastartpart'> oder</div>
      <div className='nastartpart'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker    label="" 
          inputFormat="dd.MM.yyyy"
          value={(oldna || !nastartset) ? '' : nastart}
          onChange={n => {
            setOldna(false);
            setNastartset(true)
            setNastart(n);
          }}
          renderInput={(params) => <TextField fullWidth style={{marginTop:'8px',marginBottom:'8px'}}  {...params} error={params.error && !oldna} helperText='Datum wählen'/>}
          />
        </LocalizationProvider>
      </div>
    </div>
  </Box>
  <Box className='stepperbox'>
        <Button variant='outlined' onClick={e => setActivestep(1)}>zurück</Button>
        <Button variant='contained' disabled={(!nastart || groups.length < 1 || !nastartset)} onClick={e => handleRegister()}>Registieren</Button>
  </Box>
</Paper>
}
    </DialogContent>
</Dialog>

  )

}