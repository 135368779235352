import { CheckBox, Delete, Edit, EditAttributesRounded, Mail, Report, Save, Search } from "@mui/icons-material";
import { Tabs, Tab, Box, ListItem, ListItemButton, List, ListItemText, Dialog, DialogTitle, DialogContent, Paper, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Divider, DialogActions, Button, InputAdornment, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Checkbox } from "@mui/material";
import { Container } from "@mui/system";
import { useState, useEffect } from "react";
import {useGlobal} from 'reactn';
import { MessageCenter } from "./messagecenter";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '@sweetalert2/theme-material-ui/material-ui.css';
import { Bereichsauswahl } from "./utils";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import './manage.css'
import { Bescheinigungen } from "./cme";
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import { HuePicker, SliderPicker } from "react-color";
import { format,formatISO } from "date-fns";

const swal = withReactContent(Swal)



export function ManagePortal(){
    const [isAdmin,setIsAdmin] = useGlobal('isadmin')
    const [view,setView] = useState(0)
    const location = useLocation()
    const [userid,setUserid] = useState(false)
    const navigate = useNavigate()
    
    useEffect(() => {
        //setUserid(new URLSearchParams(location.search).get('userid'))
        if (location.pathname.includes('user')) {
            setView(2)
        }
        else if (location.pathname.includes('cme')) {
            setView(1)
        }
        else if (location.pathname.includes('messagecenter')) {
            setView(0)
        }
        else if (location.pathname.includes('appbadge')) {
            setView(4)
        }        
    },[location])

    const changeView = (u,n) => {
        setUserid(u)
        setView(n)
    }
    
    
    
    return (
        <Container maxWidth='lg' fixed className='managebox'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={view} onChange={(e,n) => changeView(false,n)} centered>
                <Tab label='Nachrichtencenter' id={0}/>
                <Tab label='CME' id={1}/>
                <Tab label='User' id={2}/>
                <Tab label='Laufband' id={3}/>
                <Tab label='AppIconInfo' id={4}/>
                </Tabs>
            </Box>
            {view == 0 && <MessageCenter userid={userid} />}
            {view == 1 && <Bescheinigungen userid={userid}/>}
            {view == 2 && <Userliste userid={userid} changeview={(u,n) => changeView(u,n)}/>}
            {view == 3 && <Marquees/>}
            {view == 4 && <AppBadge/>}
            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={view} onChange={(e,n) => navigate(n)} centered>
                <Tab label='Nachrichtencenter' id={'messagecenter'}/>
                <Tab label='CME' id={'cme'}/>
                <Tab label='User' id={'userliste'}/>
                <Tab label='Laufband' id={'marquee'}/>
                </Tabs>
            </Box>
            <Routes>
                <Route path="messagecenter" element={<MessageCenter userid={userid}/>}/>
                <Route path="cme" element={<Bescheinigungen userid={userid}/>}/>
                <Route path="userliste" element={<Userliste userid={userid}/>}/>
                <Route path="marquee" element={<Marquees userid={userid}/>}/>
                <Route path="*" element={<Userliste />}/>
            </Routes> */}
        </Container>
    )
}


function Userliste({useric,changeview}) {
    const [axios] = useGlobal('axios')
    const [userliste,setUserliste] = useState([])
    const [filter,setFilter] = useState('')
    const [usertoedit,setUsertoedit] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
       getuserlist()
    },[])

     const getuserlist = () => {
        axios.get('get_userliste').then(data => {
            console.log('userliste',data)
            setUserliste(data.data)
        })
    }

    const closeUserEditDialog = () => {
        setUsertoedit(false)
        getuserlist()
    }

    const formatDate = (datum) => {
        try {
          return format(new Date(datum),'d.M.y')
        } catch {
          return '???'
        }
    }

    return (
        <Container maxWidth='sm' className='ulistcontainer'>
            <TextField label='Filter' type='search' fullWidth value={filter} onChange={e => setFilter(e.target.value.toLowerCase())} size="small" InputProps={{endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,}}/>
            <List>
            {userliste.filter(user => !filter || user?.name?.vorname?.toLowerCase().includes(filter) || user?.name?.nachname?.toLowerCase().includes(filter) || user?.email?.toLowerCase().includes(filter) || user?.mobile?.includes(filter)).sort((a,b) => Number(b.pending) - Number(a.pending)).map((user,index) => 
                <ListItem className={user.pending ? 'pending user' : 'user'} key={'userliste_'+user._id} 
                    secondaryAction={
                    <>
                        <ListItemButton edge="end" >
                            <Mail onClick={e => changeview(user._id,0)}/> 
                            <Report onClick={e => changeview(user._id,1)}/>
                            <Edit  onClick={e => setUsertoedit(user)}/>
                        
                        
                        </ListItemButton>
                        {/* <ListItemButton edge="end" onClick={e => changeview(user._id,1)}>CME</ListItemButton>
                        <ListItemButton edge="end" onClick={e => setUsertoedit(user)}><Edit/> </ListItemButton> */}
                    
                    </>
                    }>
                    <ListItemText primary={user.name.vorname+ ' '+user.name.nachname}
                    secondary = {<>
                        <div> {user.email}</div>
                        <div>{user.groups.join(', ')}</div>
                        <div><b>Letztes Login:</b> {formatDate(user.lastlogin)} {user.push ? <b>(P)</b> : ''}</div>
                        
                    
                    </>}
                    
                    />
                </ListItem>                        
            )}
            </List>
            <Edituser user={usertoedit} close={e => closeUserEditDialog()}/>
        </Container>
    )
}

function Edituser({user,close}) {
    const [axios] = useGlobal('axios')
    const [toggletasks,setToggletasks] = useGlobal('tasks')
    const [moduser,setModuser] = useState({})
    const [password,setPassword] = useState('')
    const [pw2,setPw2] = useState('')

    useEffect(() => {
        if (user) {
            setModuser(user)
        } else {
            setModuser({})
        }
    },[user])

    const checkExisting = () => {
        axios.post('check_existing',{email:moduser.email}).then(resp => 
          {if (resp.data.exists) {
            swal.fire({
              text : 'Email-Adresse schon belegt',
             confirmButtonText: 'ok',
              }).then(r => setModuser({...moduser,email:''}))
          }})
      }

    const handleUpdate = () => {axios.post('updateuser',{...moduser,password:password}).then(resp =>{
       console.log('adminupdate',resp)
       close()
      })
    }

    const deleteUser = () => {
        swal.fire({
            text : 'Benutzer entgültig löschen?',
            confirmButtonText: 'Ja',
            showCancelButton: true,
            cancelButtonText: 'Nein',
            type : 'warning',
        }).then(data => {
            if (data.value) {
             axios.post('deleteUser',{_id:user._id}).then(res => {
                setToggletasks(!toggletasks)
                close()
             })
            }
        })

    }

    const freigeben = () => {
        axios.post('freigeben',{_id:user._id}).then(res => {
            setToggletasks(!toggletasks)
            close()
        })
    }

return (
    <Dialog open={Boolean(user)}>
        <DialogTitle>Registrierung</DialogTitle>
        <DialogContent style={{padding:'5px'}}>
    <Paper>
      <TextField label="Email-Adresse" value={moduser.email} helperText='Adresse ist gleichzeitig Login-Name' error={!moduser?.email || !moduser?.email.includes('@')} fullWidth style={{marginTop:'8px'}} onChange={e => setModuser({...moduser,email:e.target.value})} onBlur={e => checkExisting()}/><br/>
      <TextField label="Passwort" value={password} error={password.length < 5} type='password' style={{marginTop:'8px',width:'50%'}} onChange={e => setPassword(e.target.value)}/>
      <TextField label="Passwort wiederholen" error={pw2 !== password} value={pw2} type='password' style={{marginTop:'8px',width:'50%'}} onChange={e => setPw2(e.target.value)}/>
    </Paper>
    <Paper>
      <FormControl style={{width:'20%',marginTop:'5px'}}>
      <InputLabel>Anrede</InputLabel>
      <Select value={moduser?.anrede} label='Anrede'  InputLabelProps={{ shrink: true }}  onChange={e => setModuser({...moduser,anrede:e.target.value})}>
        <MenuItem value={'Herr'}>Herr</MenuItem>
        <MenuItem value={'Frau'}>Frau</MenuItem>
      </Select>
      </FormControl>
      <TextField label='Vorname' style={{marginTop:'5px',width:'40%'}} error={!moduser?.name?.vorname} value={moduser?.name?.vorname || ''} onChange={e => setModuser({...moduser,name:{...moduser?.name,vorname:e.target.value}})}/>
      <TextField label='Nachname' style={{marginTop:'5px',width:'40%'}} error={!moduser?.name?.nachname} value={moduser?.name?.nachname || ''} onChange={e => setModuser({...moduser,name:{...moduser?.name,nachname:e.target.value}})}/>
      <TextField label='Handynummer'  style={{marginTop:'8px'}} fullWidth helperText='für dringende SMS' value={moduser?.mobile} onChange={e => setModuser({...moduser,mobile:e.target.value})}/>
    </Paper>
    <Paper>
      <Typography variant='h6'>In welchen NEF-Bereichen sind Sie tätig?</Typography>
      <Bereichsauswahl  selected={moduser?.groups || []} change={g => setModuser({...moduser,groups:[...g]})}/>
      <Divider/>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker    label="" 
        inputFormat="dd.MM.yyyy"
        value={moduser?.nastart}
        onChange={n => setModuser({...moduser,nastart:n})}
        renderInput={(params) => <TextField style={{marginTop:'8px'}} {...params} helperText='Datum des ersten Notarzt-Dienstes'/>}
      />
      </LocalizationProvider>
    </Paper>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" color="primary" primary="true" onClick={(event) => close()}>abbrechen</Button>
        <Button variant="contained" color="primary" primary="true" onClick={(event) => deleteUser()}>User löschen</Button>
            {user.pending && <Button variant="contained" color="primary" primary="true" onClick={(event) => freigeben()}>Freigeben</Button>}
            <Button variant="contained" color="primary" primary="true" onClick={(event) => handleUpdate()}>speichern</Button>

        </DialogActions>
    </Dialog>
)
}


function Marquees() {
    const [axios] = useGlobal('axios')
    const [marqueeliste,setMarqueeliste] = useState([])
    const [newmarquee,setNewmarquee] = useState({text:'',active:false})


    useEffect(() => {
       getmarquees()
    },[])

     const getmarquees = () => {
        axios.get('getmarquee').then(data => {
            setMarqueeliste(data.data)
            setNewmarquee({text:'',active:false,color:'#eeff00'})
        })
    }


    const modmarquee = (m) => {
        axios.post('updatemarquee',{...m,action:'update'}).then(res => getmarquees())
    }

    const delmarquee = (m) => {
        axios.post('updatemarquee',{...m,action:'delete'}).then(res => getmarquees())
    }

    const modonestext = (t,i) => {
        let om = marqueeliste
        om[i].text = t
        setMarqueeliste(om)
    }

    return (
        <Container maxWidth='sm' className='ulistcontainer'>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>aktiv</TableCell>
                            <TableCell>Text</TableCell>
                            <TableCell>löschen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {marqueeliste.map((m,i) => 
                            <TableRow>
                                <TableCell>
                                        <Checkbox checked={m.active} onClick={e => modmarquee({...m,active:!m.active})}/>
                                </TableCell>
                                <TableCell>
                                    <TextField value={m.text} onChange={e =>modonestext(e.target.value,i)}
                                        InputProps={{
                                            endAdornment :<InputAdornment position="end">
                                                    <Save onClick={e => modmarquee(m)}/>
                                                </InputAdornment>
                                            }}
                                    />
                                    <HuePicker onChangeComplete={(c,e) => modmarquee({...m,color:c.hex})} color={m?.color || '#eeff00'}/>
                                </TableCell>
                                <TableCell>
                                    <Delete onClick={e => delmarquee(m)}/>
                                </TableCell>
                                </TableRow>
                        )}
                        <TableRow>
                            <TableCell>
                                <Checkbox disabled/>
                            </TableCell>
                            <TableCell>
                                <TextField value={newmarquee?.text} onChange={e => setNewmarquee({...newmarquee,text:e.target.value})}
                                        InputProps={{
                                        endAdornment :<InputAdornment position="end">
                                                <Save onClick={e => modmarquee(newmarquee)}/>
                                            </InputAdornment>
                                        }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </TableContainer>
            {marqueeliste.map((m,index) => 
               <Paper key={'mar'+index}>


               </Paper>                     
            )}
        </Container>
    )
}



function AppBadge() {
    const [axios] = useGlobal('axios')
    const [lastmodified,setLastmodified] = useState('?')
    const [sending,setSending] = useState(false)
    const [infotext,setInfotext] = useState('Es gibt neue Inhalte')

    const getLastModified = () => {
        axios.get('lastbadge').then(data => {
            console.log(data)
            setLastmodified(data.data.lastupdate)
        })
    }

    const activateBadge = () => {
        setSending(true)
        axios.post('setbadge',{text:infotext}).then(res => {
            getLastModified()
            setSending(false)
        }).catch(e => setSending(false))
    }


    useEffect(() => {
        getLastModified()
    },[])



    return (
        <Container>
            <Typography variant="h6">Letzte Auslösung: {lastmodified}</Typography>
            <Divider/>
            <Container style={{marginTop:'10px',display:'flex', alignItems:'center',justifyContent:'center'}} >
                <TextField style={{marginRight:'10px'}} value={infotext} label='Infotext' helperText='darf nicht leer sein' onChange={e => setInfotext(e.target.value)}/>
                <Button disabled={sending} variant="contained" onClick={e => activateBadge()}>auslösen</Button>
            </Container>
        </Container>
    )
}