import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { CloudUpload, Delete, DeleteOutline, ExpandMore, HelpOutline, Logout, Settings, Streetview, Upload } from '@mui/icons-material'
import { format, set,add } from 'date-fns'
import { useEffect, useState } from 'react'
import {useGlobal} from 'reactn'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, Outlet, useNavigate } from "react-router-dom";
import './reports.css'
import { isMobile } from "react-device-detect";
import { News } from "./news";
const swal = withReactContent(Swal)


export function Reports() {
    const [axios] = useGlobal('axios')
    const [existing,setExisting] = useState([])
    const [status,setStatus] = useState('')
    const [showcme,setShowcme] = useState(false)
    const [newb,setNewb] = useState({date: format(new Date(),'yyyy-MM-dd')})
    const [view,setView] = useState(0)
    const [cmeswitch,setCmeswitch] = useGlobal('cmeswitch')
    const [showhelpdialog,setShowhelpdialog] = useState(false)
    const navigate = useNavigate()
    
    const getlist = () => {axios.get('bescheinigungen').then(data => setExisting(data.data))}

    const getstatus = () => {axios.get('checkbeschstatus',{ params: { withreports: true }}).then(data => setStatus(data.data))}
    
    useEffect(() => {
        if (axios){
        getlist()
        getstatus()
    }
    },[axios])

    useEffect(() => {
        if (cmeswitch) {
            setView(1)
            setCmeswitch(false)
        }
    },[cmeswitch])

    const uploadBescheinigung = () => {
        if (newb) {
            const formData = new FormData();
            formData.append('date',newb.date)
            formData.append('file',newb.file,newb.file.name)
            formData.append('punkte',newb.punkte)
            axios.post('uploadBescheinigung',formData).then(r => {
            swal.fire({
              text : 'Datei hochgeladen',
             confirmButtonText: 'ok',
              })
             getlist()
             setNewb({date: format(add(new Date(),{days:-1}),'yyyy-MM-dd')})
            }).error(err => {
                swal.fire({
                    icon: 'error',
                    text : 'Upload fehlgeschlagen. Im Zweifel von anderem Endgerät versuchen.',
                   confirmButtonText: 'ok',
                    })
            })
            }
    }   

    const deleteBescheinigung = (b) => {
        console.log(b)
        axios.post('deletebescheinigung',{_id:b}).then(r => getlist())
        
    }

    const downloadfile = (id) => {
        let link = document.createElement('a')
        link.href = 'https://notarzt-heinsberg.de/api/bescheinigung/'+id;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
    }

    return (
        <Container fixed maxWidth='lg' className="reportpage">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={view} onChange={(e,n) => setView(n)} centered>
                    <Tab label='Informationen' id={0}/>
                    <Tab label='Bescheinigungen' id={1}/>
                </Tabs>
            </Box>
            {view == 0 && <News fbnews={true}/>}
            {view == 1 &&
            <>
            <Box className='reportaddfilebox'>
                <div className="file">
                    {!isMobile && <HelpOutline fontSize="large" onClick={e => setShowhelpdialog(true)}/>}
                    <Button size='small' style={{flexGrow:'1'}} variant="contained" component="label">{newb?.file?.name ? newb.file.name : isMobile ? <CloudUpload/> : 'Datei auswählen'}
                            <input type='file' hidden onChange={e => setNewb({...newb,file:e.target.files[0]})}/>
                    </Button>

                    <TextField  size='small' style={{flexGrow:'2'}} label='Datum' type='date' InputLabelProps={{ shrink: true }} value={newb?.date} onChange={e => setNewb({...newb,date:e.target.value})}/>
                    <TextField size='small' style={{flexShrink:'4'}} label='Punkte' type='number' value={newb?.punkte} onChange={e => setNewb({...newb,punkte:e.target.value})}/>
                    <Button size='small' style={{flexGrow:'1'}} variant='contained' disabled={!newb.file || !newb.date || !newb.punkte} onClick={e => uploadBescheinigung()}>{isMobile ? <Upload/> :'hochladen'}</Button>
                    
                </div>
            </Box>

            <UserAccordionReports status={status} docmestuff={e => setShowcme(e)} deletebescheinigung={e => deleteBescheinigung(e)}/>
            
            <Outlet/>
            <ShowCMEDialog cme={showcme} close={e => setShowcme(false)}/>
            <ShowhelpDialog open={showhelpdialog} close={e => setShowhelpdialog(false)}/>
            </>
            }

        </Container>

    )
}



function ShowCMEDialog({cme,close}) {
    const [axios] = useGlobal('axios')
    const [modcme,setModcme] = useState({})




    return(
        <Dialog open={!!cme} fullWidth maxWidth='md' PaperProps={{style:{backgroundColor:'#dce7ffb3'}}}>
            <DialogTitle>Hochgeladene Datei</DialogTitle>
            <DialogContent>
            <div style={{height:'60vh'}}>                
            {cme && <iframe src={'https://notarzt-heinsberg.de/api/bescheinigung/'+cme._id+'#view=fitH'} title="testPdf" height="100%" width="100%" />}
            </div>             
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={e => close()}>schließen</Button>
            </DialogActions>
        </Dialog>
    )
}


export function UserAccordionReports({status,docmestuff,deletebescheinigung}) {

    return (
        <Box className="reportaddfilebox">
        {status.akt &&
        <Accordion className='reportaccordion' style={status.akt.warning}>
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography>Zeitraum {status.akt.start} bis {status.akt.ende} | anerkannte Punkte: <b>{status.akt.punkte}</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Box}>
                    <Table sx={{minWidth:400}} align='center'>
                        <TableHead>
                            <TableRow>
                                <TableCell >Dateiname</TableCell>
                                <TableCell>Datum</TableCell>
                                <TableCell >Punke</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                <TableBody>                    
            {status.akt.besch.map((b,i) => 
             <TableRow key={'beschlist'+i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">{true && <span style={{cursor:'pointer'}} onClick={e =>docmestuff(b)}><u>{b.filename}</u></span>}</TableCell>
                <TableCell >{b.datum}</TableCell>
                <TableCell >{b.punkte}</TableCell>
                <TableCell >{b.status === 'pending' ? 'in Bearbeitung' : 'angenommen'}</TableCell>
                <TableCell >{b.status === 'pending' && <DeleteOutline onClick={e => deletebescheinigung(b._id)}/>}</TableCell>
             </TableRow>
            
            
            
            )}
    </TableBody>
    </Table>
    </TableContainer>
            </AccordionDetails>

        </Accordion>
        }
        {status.pre && status.pre.punkte !== '?'  &&
        <Accordion className='reportaccordion' style={status.pre.warning}>
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography>Zeitraum {status.pre.start} bis {status.pre.ende} ({status.pre.punkte})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Box}>
                    <Table sx={{minWidth:400}} align='center' className="tableinherit">
                        <TableHead>
                            <TableRow>
                                <TableCell >Dateiname</TableCell>
                                <TableCell>Datum</TableCell>
                                <TableCell >Punke</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                <TableBody>                    
            {status.pre.besch.map((b,i) => 
             <TableRow key={'beschlist'+i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">{true && <span style={{cursor:'pointer'}} onClick={e =>docmestuff(b)}><u>{b.filename}</u></span>}</TableCell>
                <TableCell >{b.datum}</TableCell>
                <TableCell >{b.punkte}</TableCell>
                <TableCell >{b.status === 'pending' ? 'in Bearbeitung' : 'angenommen'}</TableCell>
                <TableCell >{b.status === 'pending' && <DeleteOutline onClick={e => deletebescheinigung(b._id)}/>}</TableCell>
             </TableRow>
            
            
            
            )}
    </TableBody>
    </Table>
    </TableContainer>
            </AccordionDetails>

        </Accordion>
        }
    </Box>
    )
}


function ShowhelpDialog({open,close}) {

    return (
        <Dialog open={open} onClose={e => close(true)} PaperProps={{style:{padding:'20px'},className:'helpdialog'}}>
            <DialogTitle>Hochladen einer Fortbildungsbescheinigung</DialogTitle>
            <DialogContent>
                <ul>
                    <li>PDF- oder Bild-Datei mit Klick auf <Button size="small" variant='contained'>Datei auswählen</Button> auswählen</li>
                    <li>Datum der Fortbildung und erhaltene CME-Punkte einstellen</li>
                    <li>Hochladen per Klick auf <Button size="small" variant='contained'>Hochladen</Button></li>
                </ul>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={e => close(true)}>schließen</Button>
            </DialogActions>
        </Dialog>
    )


}