

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
  .replace(/\-/g, '+')
  .replace(/_/g, '/');
  
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}



function sendSubscription(axios,subscription) {
    console.log('send subscription',axios,subscription)
    axios.post('subscription',{subscription_token:subscription}).then(data => console.log('subscription sent'))
}

export function subscribeUser(axios,vapidkey) {
  const convertedVapidKey = urlBase64ToUint8Array(vapidkey)
  console.log('convertedVapidKey',convertedVapidKey)
  if ('serviceWorker' in navigator) {
    console.log('serviceWorker in navidator',navigator)


 
    navigator.serviceWorker.ready.then(function(registration) {
      if (!registration.pushManager) {
        console.log('Push manager unavailable.')
        return
      }
      console.log('push manager',registration.PushManager)
      registration.pushManager.getSubscription().then(function(existedSubscription) {
        if (existedSubscription === null) {
          console.log('No subscription detected, make a request.')
          registration.pushManager.subscribe({
            applicationServerKey: convertedVapidKey,
            userVisibleOnly: true,
          }).then(function(newSubscription) {
            console.log('New subscription added.')            
            sendSubscription(axios,newSubscription)
          }).catch(function(e) {
            console.log('Catch',e)
            if (Notification.permission !== 'granted') {
              console.log('Permission was not granted.')
            } else {
              console.error('An error ocurred during the subscription process.', e)
            }
          })
        } else {
          console.log('Existed subscription detected.')
          sendSubscription(axios,existedSubscription)
        }
      })
    })
      .catch(function(e) {
        console.error('An error ocurred during Service Worker registration.', e)
      })
  } else {
    console.log('no serviceWorker registrated')
  }
}