import { Delete } from '@mui/icons-material';
import { Autocomplete, Box, Button, Chip, FormControlLabel, Snackbar, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import {useGlobal} from 'reactn';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './manage.css'
import { useRef } from 'react';

const swal = withReactContent(Swal)

const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

const bereiche = ['ERK','GEI','HSB','WGB','LNA']

export function MessageCenter({userid}) {
    const [axios] = useGlobal('axios')
    const [fulllist,setFulllist] = useState([])
    const [reciever,setReciever] = useState([])
    const [message,setMessage] = useState({subject:'',text:''})
    const [plaintext,setPlaintext] = useState('')
    const [messagetyp,setMessagetyp] = useState(0)
    const [sender,setSender] = useState('NAHS')
    const [mailsender,setMailsender] = useState('aelrd@rd-heinsberg.de')
    const [smssenderlist,setSmssenderlist] = useState(['NA HS','AELRD','RD HS'])
    const [mailsenderlist,setMailsenderlist] = useState(['aelrd@rd-heinsberg.de'])
    const [infomessage,setInfomessage] = useState(false)
    const quillRef = useRef();


    useEffect(() => {
        axios.get('get_userliste').then(data => setFulllist(data.data))
        axios.get('getmynumber').then(data => {
          setSender(data.data.sms);
          setMailsender(data.data.mail)
          setSmssenderlist([...smssenderlist,data.data.sms])
          setMailsenderlist([...mailsenderlist,data.data.mail])
        })

    },[])

    useEffect(() => {
      if (userid) {
        axios.get('userdata',{ params: { user: userid} }).then(u => setReciever([u.data]))
      } else {
        setReciever([])
      }
    },[userid])


    useEffect(() => {
      console.log(quillRef)
    },[messagetyp])


    const sendmessage = () => {
        axios.post('sendmessage',{reciever:reciever,message:message,messagetyp:messagetyp,sender:messagetyp === 0 ? sender : mailsender}).then(data => {
          if (data.data.sent){
            setReciever([])
            setInfomessage(data.data.message)
          } else {
            setInfomessage('Irgendwas ist schief gelaufen')
          }
        })
    }

    return (
        <Container className='messagecontainer'>
            <Box className='reciever'>
                <Box className='recbuttons'>
                    {bereiche.map((b,i) => <Button key={'fastbutton_'+i} variant='outlined'  onClick={e => setReciever([...reciever.filter(r => !r.groups.includes(b)),...fulllist.filter(r => r.groups.includes(b))])}>{b}</Button>)}
                    <Button variant='contained' onClick={e => setReciever(fulllist)}>alle</Button>
                </Box>
                <Autocomplete multiple freeSolo options={fulllist} getOptionLabel={(o) => o.name?.vorname+' '+o.name?.nachname} filterSelectedOptions
                    onChange={(event, newValue) => {setReciever(newValue);}} autoSelect
                    value={reciever}
                    renderTags={(value, getTagProps) =>
                        value.map((o, index) => (
                          <Chip variant='outlined' size="small" label={o.name ? o.name?.vorname+' '+o.name?.nachname : o} {...getTagProps({ index })} />
                        ))}
                    renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Empfänger"
                        />
                      )}
                
                />
            </Box>
            <Box className='message'>
                <Tabs value={messagetyp} onChange={(e,mt) => setMessagetyp(mt)} style={{margin:'10px'}} centered>
                    <Tab label='SMS' id={0}/>
                    <Tab label='Email' id={1} />
                </Tabs>
                {messagetyp === 0 && 
                <>
                <Autocomplete freeSolo options={smssenderlist} getOptionLabel={(o) => o} filterSelectedOptions disableClearable
                  onChange={(e,nv) => setSender(nv.slice(0,11))} autoSelect value={sender} 
                  renderInput={(params) => ( <TextField {...params} variant="filled" label="Absender" /> )}
                  />
                <TextField label="SMS" style={{marginTop:'7px'}} multiline  rows={4} fullWidth value={message.text} onChange={e => setMessage({...message,text:e.target.value})} />
                </>
                }
                {messagetyp === 1 && 
                <>
                <Autocomplete options={mailsenderlist} getOptionLabel={(o) => o} filterSelectedOptions disableClearable
                  onChange={(e,nv) => setMailsender(nv)} autoSelect value={mailsender} 
                  renderInput={(params) => ( <TextField {...params} variant="filled" label="Absender" /> )}
                  />                
                <TextField label='Betreff' fullWidth value={message.subject} onChange={e => setMessage({...message,subject:e.target.value})} />
                <ReactQuill theme="snow" defaultValue={message.text} ref={quillRef}
                onChange={v => setMessage({...message,text:v})} 
                preserveWhitespace={true}  modules={modules} formats={formats} />
                </>
                }
            </Box>
        
        <Button className='sendbutton' variant='contained' onClick={e => sendmessage()}>Absenden</Button>
        <Snackbar open={!!infomessage} autoHideDuration={5000} onClose={e => setInfomessage(false)} message={infomessage}/>
        </Container>
    )

}