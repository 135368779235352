import Axios from 'axios'
import {useGlobal} from 'reactn';
import { useEffect, useState, useRef } from "react";
import useAxios from "axios-hooks";
import useFileUpload from 'react-use-file-upload';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Input, InputAdornment, Stack, Switch, TextField, Typography } from '@mui/material';
import {bereiche, Bereichsauswahl} from './utils';
import { Cancel, ContentCopy, Download } from '@mui/icons-material';
import copy from "copy-to-clipboard";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './filepage.css'

const swal = withReactContent(Swal)




export function FileDialog({modfile,close}) {
    const [axios,setAxios] = useGlobal('axios')
    const [folderdata,setFolderdata] = useState([])
    const [file,setFile] = useState()
    const [beschreibung,setBeschreibung] = useState('')
    const [link,setLink] = useState('')
    const [folder,setFolder] = useState('')
    const [groups,setGroups] = useState([])
    const [directdownload,setDirectdownload] = useState(false)

    useEffect(() => {
        if (modfile === 'new') {
            setFile()
            setBeschreibung('')
            setGroups(bereiche)
            setFolder('')
            setLink('')
        } else {
            setBeschreibung(modfile.beschreibung || '')
            setFolder(modfile.origfolder || modfile.folder || '')
            setGroups(modfile.groups || bereiche)
            setFile({filename:modfile.filename})
            setLink(modfile.link)
        }
        axios.get('flat_folderlist').then(data => setFolderdata(data.data))
    },[modfile])

    const saveFile = () => {
        if (!modfile._id){
            const formData = new FormData();
            formData.append('beschreibung',beschreibung)
            if (file)
                formData.append('file',file,file.name)
            else if (link) 
                formData.append('link',link)
            formData.append('folder',folder)
            formData.append('groups',groups)
            axios.post('uploadFile',formData)
        } else {
            axios.post('updateFile',{...modfile,beschreibung:beschreibung,folder:folder,groups:groups,download:directdownload,link:link})
        }
        close(true)
    }

    const delFile = (index) => {
        swal.fire({
                text : 'Datei/Link aus Sammlung löschen?',
                confirmButtonText: 'Ja',
                showCancelButton: true,
                cancelButtonText: 'Nein',
                type : 'warning',
            }).then(data => {
                if (data.value) {
                    console.log(data.value)
                axios.post('deleteFile',{id:modfile._id}).then(close(true))
                } else {
                    close(false)
                }
            })
    }

    const downloadfile = (id) => {
        let link = document.createElement('a')
        link.href = 'https://notarzt-heinsberg.de/api/file/'+id;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
    }


    const copyToClipboard = (link) => {
        copy(link)
        swal.fire({
            text : 'Link in Zwischenablage kopiert',
            confirmButtonText  : 'ok'
        })
    }

    return (
    <Dialog open={!!modfile} close={e => close(false)} fullWidth maxWidth='md' >
    <DialogTitle>
    <DialogTitle>Datei/Link {modfile._id  ? 'bearbeiten' : 'hochladen'}</DialogTitle>
    </DialogTitle>
    <DialogContent>
    <Bereichsauswahl selected={groups} change={g => setGroups([...g])}/>
    <div className='folderselect'>
        <FormControlLabel label='Download bei Klick' control={<Switch onClick={e => setDirectdownload(!directdownload)} checked={directdownload} />}/>
        <div>
            <Stack spacing={2} sx={{ width: 300 }}>
                <Autocomplete id="folderinput" fullWidth freeSolo inputValue={folder}
                        onInputChange={(event, newValue) => {setFolder(newValue);}}
                        options={folderdata}                                              
                        renderInput={(params) => <TextField {...params} label="Ordner" helperText='Unterordner getrennt mit "/" (Shift + 7)'/>}
                    />
            </Stack>
        </div>

    </div>
    <Divider/>
    <div className='fileorlink'>
    <Typography variant='h6'>Datei/Link</Typography>
    <div className="file">
        {modfile._id ? <TextField value={file.filename} disabled={true}/>: 
                                    <Button variant="contained" component="label">
                                    {file?.name ? file.name : 'Datei hochladen'} 
                                    <input type='file' hidden onChange={e => setFile(e.target.files[0])}/>
                                </Button>
        }
        <Typography style={{margin:'5px',textAlign:'center'}}> oder </Typography>
    <TextField disabled={!(!file || modfile?.mimetype == 'link')} className='filecaption' label='Link' value={link || ''} onChange={e => setLink(e.target.value)}/>

    </div>
    <Divider style={{width:'100%', marginBottom:'5px'}}/>
    <TextField className='filecaption' style={{width:'80%'}} label='Beschreibung' value={beschreibung || ''} onChange={e => setBeschreibung(e.target.value)}/>

    </div>

    {modfile._id && !modfile.link && <div>
        <TextField label='direktlink'  fullWidth value={'https://notarzt-heinsberg.de/files/'+modfile._id} InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ContentCopy onClick={e => copyToClipboard('https://notarzt-heinsberg.de/files/'+modfile._id)}/>
            </InputAdornment>
          ),
        }}/>  
    </div> }
    

    </DialogContent>
    <DialogActions>
                {modfile._id && !modfile.mimetype == 'link' && <Download onClick={e => downloadfile(modfile._id)} sx={{marginRight:'10px'}}/>}
                <Button variant='outlined' onClick={e => close(false)}>abbrechen</Button>
                {modfile._id && <Button variant='outlined' onClick={e => delFile()}>Datei/Link löschen</Button>}
                <Button variant='contained' onClick={e => saveFile()}>{modfile ==='new' ? 'speichern' : 'Änderungen speichern'}</Button>
    </DialogActions>
    </Dialog>
)

}

