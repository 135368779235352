import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Paper, Stack, Switch, TextField } from "@mui/material";
import useAxios from "axios-hooks";
import { useEffect, useState, useRef } from "react";
import {useGlobal} from 'reactn';
import {bereiche, Bereichsauswahl} from './utils';
import ReactQuill, {Quill} from 'react-quill';
//import BlotFormatter from 'quill-blot-formatter';
import ImageResize from 'quill-image-resize-module-react';
import { addYears, format } from 'date-fns'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import 'react-quill/dist/quill.snow.css';
import './nahs.css';
import { Add, Cancel } from "@mui/icons-material";
//import CustomImage from "./customimageclass";
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import { DeleteAction } from "quill-blot-formatter";

//Quill.register('modules/imageActions', ImageActions);
//Quill.register('modules/imageFormats', ImageFormats);

Quill.register('modules/imageResize', ImageResize);
//Quill.register('modules/blotFormatter', BlotFormatter);
//Quill.register('formats/image', CustomImage);


const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    // imageActions: {},
    // imageFormats: {},
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
     }
  }

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

const swal = withReactContent(Swal)


// swal.fire({
//     text : 'SMS senden?',
//     confirmButtonText: 'Ja okay',
//     showCancelButton: true,
//     cancelButtonText: 'Abbrechen',
//     type : 'warning',
//   }).then(data => {
//     console.log(data)
//     if (data.value) {
//       sendData('sendsms',{text:text,reciever:filtered,sender:sender},data => {
//             swal.fire({text : data === true ? 'SMS gesendet':'SMS senden fehlgeschlagen',
//                 confirmButtonText: 'verstanden',
//                 type : (data === true ? 'info' : 'warning'),
//               })
        

//        })
//   }})


const plainentry = {
    html: '',
    groups : [],
    title : '',
    files : [],
    start : '',
    end : '',
    _id : false
}


export function ModNews({modentry,close,fbnews}) {
    const [entry,setEntry] = useState({})
    const [informuser,setInformuser] = useState(false)
    const [axios,setAxios] = useGlobal('axios')
    const [folderdata,setFolderdata] = useState([])
    const [loadQuill,setLoadQuill] = useState(false)
    const quillRef = useRef();


    useEffect(() => {
        axios.get('folderlist').then(data => setFolderdata(data.data))
        if (modentry === 'new') {
            setEntry({...plainentry,start:format(new Date(),'yyyy-MM-dd'),end:format(addYears(new Date(),5),'yyyy-MM-dd'),groups:bereiche})
            setLoadQuill(true)
        } else if (modentry) {
            setEntry({...plainentry,...modentry,
                               _id:modentry._id,
                                files:modentry.files || [],
                                groups:modentry.groups || bereiche,
                                start:modentry.start,
                                end:modentry.end,
                                html:modentry.html || '',
                                fbnews:fbnews,
                                title:modentry.title || ''
                            }) //,start:format(new Date(),'yyyy-MM-dd')})
            console.log('entry for update')
            setLoadQuill(true)
            // if (quillRef?.current && modentry.html) {
            //     console.log('quill')
            //     console.log(quillRef)
            //     //quillRef.getEditor().setContents(modentry.html)
            //     //quillRef.clipboard.dangerouslyPasteHTML(modentry.html)
            // }
        } else {
            setLoadQuill(false)
        }
    },[modentry])


    

    const modFileEntry = (i,val,key) => {
        let newfilelist = entry.files
        newfilelist[i] = {...newfilelist[i],[key]:val}
        setEntry({...entry,files:newfilelist})
    }
    
    const delFile = (index) => {
        if (entry.files[index]._id) {
            swal.fire({
                text : 'Datei auch aus Dateisammlung löschen?',
                confirmButtonText: 'Ja',
                showCancelButton: true,
                cancelButtonText: 'Nein',
                type : 'warning',
            }).then(data => {
                if (data.value) {
                 axios.post('deleteFile',{_id:entry.files[index]._id})
                }
            })          
        }
        let newfilelist = entry.files
        newfilelist.splice(index,1)
        setEntry({...entry,files:newfilelist})
    }



    const axiosUploadFile = async (formData) => {
        const result = await axios.post('uploadFile',formData)
        console.log('file',result)
        return result
    }


    const saveEntry = () => {
        let filelist = entry.files || []
        console.log(modentry,entry)
        const upl =  Promise.all(filelist.map((file,index) => {
            if (!file._id && file.blob) {
                let formData = new FormData();
                formData.append('file',file.blob,file.blob.name)
                formData.append('beschreibung',file.beschreibung)
                formData.append('groups',entry.groups)
                formData.append('folder',file.folder)
                return axios.post('uploadFile',formData).then(result => {
                console.log('file uploaded',result.data)
                filelist[index] = {'_id':result.data._id,filename:result.data.filename,beschreibung:result.data.beschreibung,folder:result.data.folder}
                })
            } else {
                return Promise.resolve()
            }
        })).then(done => {
            console.log('all done, send')
            axios.post('updateEntry',{entry:{...entry,files:filelist,'_id':modentry?._id,fbnews:fbnews},action:'update',informuser:informuser}).then(e => close(true))
        })
    }

    const deleteEntry = () => {
        swal.fire({
            text : 'Eintrag entgültig löschen?',
            confirmButtonText: 'Ja',
            showCancelButton: true,
            cancelButtonText: 'Nein',
            type : 'warning',
        }).then(data => {
            if (data.value) {
                axios.post('deleteEntry',{_id:modentry._id}).then(r => close(true))
            }
        })
    }



    return (
        <Dialog open={!!modentry} onClose={e => close()} fullWidth maxWidth='xl'>
            <DialogTitle>Beitrag {modentry === 'new' ? 'erstellen' : 'bearbeiten'}</DialogTitle>
            <DialogContent>
                <TextField label='Titel' onChange={e => setEntry({...entry,title:e.target.value})} value={entry.title} fullWidth className='textfield' InputLabelProps={{ shrink: true }}/>
                <div className='zeitraum'>
                    <TextField className='textfield50' InputLabelProps={{ shrink: true }} label='Von' helperText='gleichzeitig Datum des Eintrags' value={entry.start} type='date' onChange={e => setEntry({...entry,start:e.target.value})}/>
                    <TextField className='textfield50' InputLabelProps={{ shrink: true }} label='Bis' helperText='bis wann soll der Beitrag angezeigt werden' value={entry.end} type='date' onChange={e => setEntry({...entry,end:e.target.value})}/>
                </div>
                <Bereichsauswahl selected={entry?.groups || []} change={g => setEntry({...entry,groups:g})}/>
                {loadQuill && <ReactQuill theme="snow"  defaultValue={entry.html} ref={quillRef} 
                            // onBlur={v => setEntry({...entry,html:v})}
                            onChange={v => setEntry({...entry,html:v})}
                            preserveWhitespace={true}  
                            modules={modules} formats={formats} />
                }
                <Button variant='outlined' onClick={e => setEntry({...entry,files:[...entry.files,{}]})}>Datei hinzufügen</Button>
                <Paper className='filelist'>
                    {entry.files && entry.files.map((file,index) => 
                        <div className="file" key={'file_'+index}>
                            {file._id ? <TextField value={file.filename} disabled={true}/>: 
                            <Button variant="contained" component="label">
                                {file.blob?.name ? file.blob.name : 'Datei hochladen'}
                                <input type='file' hidden onChange={e => modFileEntry(index,e.target.files[0],'blob')}/>
                            </Button>
                            }
                            <TextField label='Beschreibung' multiline value={file.beschreibung || ''} onChange={e => modFileEntry(index,e.target.value,'beschreibung')}/>
                            <Stack spacing={2} sx={{ width: 300 }}>
                                <Autocomplete id="folderinput"  freeSolo inputValue={file.folder || ''}
                                        onInputChange={(event, newValue) => modFileEntry(index,newValue,'folder')}
                                        options={folderdata}                                              
                                        renderInput={(params) => <TextField {...params} label="Ordner" />}
                                    />
                            </Stack>
                            <div><Cancel className='delicon' onClick={e => delFile(index)}/></div>
                        </div>
                    )}
                </Paper>
                <FormControlLabel label='Adressaten per Mail informieren' control={<Checkbox checked={informuser} />} onClick={e => setInformuser(!informuser)}/>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={e => close(false)}>abbrechen</Button>
                <Button variant='outlined' onClick={e => deleteEntry()}>Beitrag löschen</Button>
                <Button variant='contained' onClick={e => saveEntry()}>speichern</Button>
            </DialogActions>
        </Dialog>
    )
}




