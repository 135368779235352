import { Dialog, ListItemIcon, Menu, MenuItem, Button, DialogActions, DialogContent, DialogTitle, Link, Paper, TextField, Typography, ListItem, Select, FormControl, InputLabel, List, ListItemText, ListItemSecondaryAction, ListItemButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Delete, DeleteOutline, Logout, Settings } from '@mui/icons-material'
import { add, format, set } from 'date-fns'
import { useEffect, useState } from 'react'
import {useGlobal} from 'reactn'
import { Bereichsauswahl } from "./utils";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from "react-router";
import '@sweetalert2/theme-material-ui/material-ui.css';
import './user.css'
import { unregister } from "./serviceWorkerRegistration";


const swal = withReactContent(Swal)



export function MenuDing({version}) {
    const [axios] = useGlobal('axios')
    const [cmeswitch,setCmeswitch] = useGlobal('cmeswitch')
    const [menu,setMenu] = useState(null)
    const [standalone,setStandalone] = useState(false)
    
    const [settings,setSettings] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setStandalone(isInstalled())
    },[])

    function isInstalled() {
        // For iOS
        if(window.navigator.standalone) return true
        // For Android
        if(window.matchMedia('(display-mode: standalone)').matches) return true      
        // If neither is true, it's not installed
        return false
      }


    const logout = () => {
        setMenu(null)
        axios.post('logout').then(res => axios.post('login',{username:'',password:''}))
      }

    const opensettings = (v) => {
        setMenu(null)
        setSettings(v)
    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      };

    const forced_reload = () => {
        unregister();
        clearCacheData();
        window.location.reload(true);
    }

    const close = () => {
        window.close()
    }

    return (
        <div className='topright'>
            <Settings onClick={e => setMenu(e.currentTarget)}/>
            <Menu open={Boolean(menu)} onClose={e => setMenu(null)}  anchorEl={menu} anchorOrigin={{vertical: 'top',horizontal: 'right',}} transformOrigin={{vertical: 'top',horizontal: 'right',}}>
                <MenuItem onClick={e => opensettings('settings')} >
                    <ListItemIcon>
                        <Settings fontSize='small'/>
                        Einstellungen
                    </ListItemIcon>
                </MenuItem>
                {/* <MenuItem onClick={e => opensettings('bescheinigungen')}> */}
                <MenuItem onClick={e => {setMenu(null);setCmeswitch(true);navigate('fortbildungen')}}>
                    <ListItemIcon>
                        Bescheinigungen
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={e => logout()}>
                    <ListItemIcon>
                        <Logout fontSize='small'/>
                        Abmelden
                    </ListItemIcon>
                </MenuItem>
                {standalone && <MenuItem onClick={e => close()}>
                    <ListItemIcon>
                        <Logout fontSize='small'/>
                        Schließen
                    </ListItemIcon>
                </MenuItem>    
                }
                <Typography variant="caption" className='versioninfo' style={{cursor:'pointer'}} onDoubleClick={e => forced_reload()} >v{version}</Typography>
            </Menu>
            {settings === 'settings' && <UserSettings open={settings ==='settings'} close={e => setSettings(false)}/>}
            {settings === 'bescheinigungen' && <Bescheinigungen open={settings === 'bescheinigungen'} close={e => setSettings(false)}/>}
        </div>
    )

}


function UserSettings({open,close}) {
    const [axios] = useGlobal('axios')
    const [userdata,setUserdata] = useState({})
    const [pw2,setPw2] = useState('')
    const [notvalid,setNotvalid] = useState(true)

    useEffect(() => {
        if (open) 
            axios.get('userdata').then(data => {
                setUserdata({...data.data})
                setTimeout(() => setNotvalid(true),500)
            })
    },[open])

    useEffect(() => {
        setNotvalid((userdata.password && userdata.password !== pw2) || !userdata.name?.vorname || !userdata.name?.nachname || !userdata.mobile)
    },[userdata,pw2])

    const handleChangeSettings = () => {
        axios.post('updateuser',{...userdata}).then(r => {
            swal.fire({
          text : 'Daten geändert',
         confirmButtonText: 'ok',
          })
         close()
        })
    }

    return (
        <Dialog open={open} PaperProps={{style:{padding:'20px'}}}>
        <DialogTitle>Benutzerdaten</DialogTitle>
        <DialogContent style={{padding:'5px'}}>
    <Paper>
      <TextField disabled label="Email-Adresse" value={userdata.email} fullWidth style={{marginTop:'5px'}}/><br/>
      <TextField label="Passwort" value={userdata?.password || ''} type='password' style={{marginTop:'5px',width:'50%'}} onChange={e => setUserdata({...userdata,password:e.target.value})}/>
      <TextField label="Passwort wiederholen" error={userdata?.password  && pw2 !== userdata.password} value={pw2} type='password' style={{marginTop:'5px',width:'50%'}} onChange={e => setPw2(e.target.value)}/>
    </Paper>
    <Paper>
    <FormControl style={{width:'20%',marginTop:'5px'}}>
        <InputLabel>Anrede</InputLabel>
        <Select value={userdata?.anrede || ''} InputLabelProps={{ shrink: true }} label='Anrede'  onChange={e => setUserdata({...userdata,anrede:e.target.value})}>
          <MenuItem value={'Herr'}>Herr</MenuItem>
          <MenuItem value={'Frau'}>Frau</MenuItem>
        </Select>
    </FormControl>
      <TextField label='Vorname' style={{marginTop:'5px',width:'40%'}} error={!userdata?.name?.vorname} value={userdata?.name?.vorname || ''} onChange={e => setUserdata({...userdata,name:{...userdata?.name,vorname:e.target.value}})}/>
      <TextField label='Nachname' style={{marginTop:'5px',width:'40%'}} error={!userdata?.name?.nachname} value={userdata?.name?.nachname || ''} onChange={e => setUserdata({...userdata,name:{...userdata?.name,nachname:e.target.value}})}/>
      <TextField label='Handynummer' fullWidth style={{marginTop:'5px'}} helperText='für dringende SMS' value={userdata?.mobile || ''} onChange={e => setUserdata({...userdata,mobile:e.target.value})}/>
    </Paper>
    <Paper>
      <Typography variant='h6'>In welchen NEF-Bereichen sind Sie tätig?</Typography>
      <Bereichsauswahl  selected={userdata.groups || []} disabled={true} />
    </Paper>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" color="primary" primary="true" onClick={(event) => close()}>abbrechen</Button>
            <Button variant="contained" color="primary" primary="true" disabled={notvalid} onClick={(event) => handleChangeSettings()}>ändern</Button>
        </DialogActions>
    </Dialog>
    )

}


function Bescheinigungen({open,close}) {
    const [axios] = useGlobal('axios')
    const [existing,setExisting] = useState([])
    const [status,setStatus] = useState('')
    const [newb,setNewb] = useState(false)


    const getlist = () => {axios.get('bescheinigungen').then(data => setExisting(data.data))}

    const getstatus = () => {axios.get('checkbeschstatus').then(data => setStatus(data.data))}
    


    useEffect(() => {
        if (open) 
            getlist()
            getstatus()
    },[open])

    const uploadBescheinigung = () => {
        if (newb) {
            const formData = new FormData();
            formData.append('date',newb.date)
            formData.append('file',newb.file,newb.file.name)
            formData.append('punkte',newb.punkte)
            axios.post('uploadBescheinigung',formData).then(r => {
            swal.fire({
              text : 'Datei hochgeladen',
             confirmButtonText: 'ok',
              })
             getlist()
             setNewb(false)
            })
            }
    }

    const deleteBescheinigung = (b) => {
        console.log(b)
    }

    // const getbescheinigung = (id) => {
    //     axios.get('getbescheinigung',{params:{_id:id},responseType:'stream'}).then(r => r.data.pipe(createWriteStream('file_name.jpg')))
    // }

    return (
        <>
        <Dialog open={open} maxWidth={'sm'} fullWidth >
            <DialogTitle>Bescheinigungen</DialogTitle>
            <DialogContent style={{padding:'5px'}}>
                <Button variant='contained' color='primary' onClick={e => setNewb({date: format(add(new Date(),{days:-1}),'yyyy-MM-dd')})}>neue Bescheinigung hochladen</Button>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth:400}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Dateiname</TableCell>
                                <TableCell>Datum</TableCell>
                                <TableCell>Punke</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                <TableBody>                    
                {existing.map((b,i) => 
                <TableRow key={'beschlist'+i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row"><Link target="_blank" href={'https://notarzt-heinsberg.de/api/getbescheinigung?_id='+b._id}>{b.filename}</Link></TableCell>
                    <TableCell align="right">{b.datum}</TableCell>
                    <TableCell align="right">{b.punkte}</TableCell>
                    <TableCell align="right">{b.status === 'pending' ? 'in Bearbeitung' : ''}</TableCell>
                    <TableCell align="right">{b.status === 'pending' && <DeleteOutline onClick={e => deleteBescheinigung(b._id)}/>}</TableCell>
                
                </TableRow>
                )}
                </TableBody>
                </Table>
                </TableContainer>
            {status && <Typography>Es werden noch <b>{(20-status.punkte) || '?'}</b> Punkte bis zum {format(new Date(status.ende),'d.M.y')} benötigt.</Typography>}
            </DialogContent>
            <DialogActions>
            <Button variant="contained" color="primary" primary="true" onClick={(event) => close()}>schließen</Button>
            </DialogActions>
        </Dialog>       

        <Dialog open={Boolean(newb)}>
            <DialogContent>
                <div className="file">
                
                                            <Button variant="contained" component="label">
                                            {newb?.file?.name ? newb.file.name : 'Datei hochladen'} 
                                            <input type='file' hidden onChange={e => setNewb({...newb,file:e.target.files[0]})}/>
                                        </Button>
                
                <TextField label='Datum' type='date' InputLabelProps={{ shrink: true }} value={newb?.date || format(new Date(),'yyyy-MM-dd')} onChange={e => setNewb({...newb,date:e.target.value})}/>
                <TextField label='Punkte' type='number' value={newb?.punkte} onChange={e => setNewb({...newb,punkte:e.target.value})}/>
                </div>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" color="primary" primary="true" onClick={(event) => setNewb(false)}>abbrechen</Button>
            <Button variant="contained" color="primary" disabled={!newb.date || !newb.file || !newb.punkte} primary="true" onClick={(event) => uploadBescheinigung()}>hochladen</Button>
        </DialogActions>
        </Dialog>
        </>
    )
    
}