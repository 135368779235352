import { Button, Container, Divider, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import {useGlobal} from 'reactn';
import { ModNews } from "./modnews";
import DOMPurify from 'dompurify'
import { Edit, Search, Settings, SettingsSystemDaydreamTwoTone } from "@mui/icons-material";
import './nahs.css'
import { format, parseISO } from "date-fns";
import { isMobile } from "react-device-detect";

const VERSION= 1.8

export function News({fbnews}) {
    //const [{data,loading, error}, getblog] = useAxios({url: 'blog' })
    const [data,setData] = useState([])
    const [axios,setAxios] = useGlobal('axios')
    const [showas,setShowas] = useGlobal('showas')
    const [isAdmin,setIsAdmin] = useGlobal('admin')
    const [modnews,setModnews] = useState(false)

    const getEntries = () => { 
        if (fbnews){
            axios.get('blog_fb').then(data => {setData(data.data)})
        }
        else {
            axios.get('blog').then(data => {setData(data.data)})
        }
    }

    useEffect(() => {
        if (axios)
            getEntries()
    },[axios])

    const modDialogClose = (modded) => {
        setModnews(false)
        if (modded) {
            getEntries()
        }
    }
    

    // useEffect(() =>{if (loggedin) getblog()},[loggedin])

    // useEffect(() => {console.log(data)},[data])

    
    return (
        <Container fixed maxWidth='lg' className="newsblog">
            <div className="firstrow">
            {/* <TextField type='search' className='searchfield' size="small" InputProps={{endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,}}/> */}
            {isAdmin && !showas && <Button onClick={e => setModnews('new')} variant="contained" className='addentry'> Neuer Betrag</Button>}
            </div>
            {data &&  data.map((entry,i) => <BlogEntry key={'entry_'+i} admin={isAdmin} showas={showas} entry={entry} editme={entry => setModnews({...entry})} fbnews={fbnews}/>)}            
            {isAdmin && !showas && <ModNews modentry={modnews} close={r => modDialogClose(r)} fbnews={fbnews}/>}
        </Container>
    )

}


function BlogEntry({entry,editme,admin,fbnews,showas}) {
    const today = new Date()

    const isvisible = () => {
        return (parseISO(entry.start,'yyyy-MM-dd') > today || (entry?.end && parseISO(entry.end,'yyyy-MM-dd') < today) ? 'bloghidden' : '')
    }

   
    return (
        <Paper className={'blogentry '+ isvisible() }  >
            {admin && !showas && <Edit onClick={e => editme(entry)} className='topright'/> }
            {isMobile ? 
            <>
            <Typography variant='h6'>
                    {entry.title}
            </Typography>
            {!fbnews && <Typography variant='caption'>
                    {entry.start}
            </Typography>}
            </>
            :
            <>
            <Typography variant='h4'>
                    {entry.title}
            </Typography>
            {!fbnews && <Typography variant='h6'>
                    {entry.start}
            </Typography>}
            </>
            }
            <Divider/>
            <div className='entrytext' dangerouslySetInnerHTML={{__html:entry.html}}></div>
            <Divider/>
            {entry.files && (entry.files.length > 0) && <>Dateien:<br/>
            {entry.files && entry.files.map((f,i) => 
            <>
                <Link  href={'https://notarzt-heinsberg.de/api/file/'+f._id} download>{f.filename}</Link><br/>
            </>
            )}</>}
        </Paper>
    )
}