import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";



export function OneFile({}) {
    const {file} = useParams()
    const navigate = useNavigate()

    useEffect(() =>{

        
        let link = document.createElement('a')
        link.href = 'https://notarzt-heinsberg.de/api/file/'+file;
        document.body.appendChild(link);
        link.click();
        navigate('/files')
        
    },[file])


    return null;


}